<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/bookManage' }">册本管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增册本</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="区域" prop="regionId">
          <el-select v-model="form.regionId" placeholder="请选择区域">
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入册本名称" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push('/bookManage')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        regionId: null
      },
      regionList: [],
      rules: {
        regionId:[{ required: true, message: '必填项', trigger: 'change' }],
        name:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      loading: false,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$ajax.post("zoneUpdate", this.form).then((res) => {
            this.loading = false;
            this.$message.success('成功')
            this.$router.push('/bookManage')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    // 获取行政区域
    loadRegion() {
      this.$ajax.post("regionQuery").then((res) => {
        this.regionList = res.data;
      });
    }
  },
  mounted() {
    if (this.$route.params == null) {
			this.$router.push('/bookManage')
		} else {
      this.loadRegion()
      this.form = this.$route.params
      console.log(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
.mains {
  height: 80%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-select {
  width: 100%;
}
</style>